<template>
  <div>
    <div id="form">
      <qr-code :text="`cheqr.cc/${this.pID}`" :key="this.pID" error-level="Q" :size="512" class="qr" />
      <br />
      <input id="alphanumeric" v-model="pID" v-on:input="checkProfileID(pID)" type="text" placeholder="Profile ID" :class="{'outline-green': profileOK, 'outline-red': !profileOK}">
      <button v-on:click="pID = generateAlphanumeric(6)" class="diceBtn">🎲</button>
      <input id="pName" v-model="pName" type="text" placeholder="Full name">
      <input id="pAffiliation" v-model="pAffiliation" type="text" placeholder="Affiliation">
      <input id="pRole" v-model="pRole" type="text" placeholder="Role description">
      <input id="pMobile" v-model="pMobile" type="text" placeholder="Phone number">
      <input id="pEmail" v-model="pEmail" type="text" placeholder="E-mail address">
      <div class="permission">
        <span class="ptag">3D</span>
        <input id="p3D01" v-model="p3D01" type="date">
      </div>
      <div class="permission">
        <span class="ptag">CNC</span>
        <input id="pCNC01" v-model="pCNC01" type="date">
      </div>
      <div class="permission">
        <span class="ptag">Laser</span>
        <input id="pLaser01" v-model="pLaser01" type="date">
      </div>
      <div class="permission">
        <span class="ptag">Router</span>
        <input id="pRouter01" v-model="pRouter01" type="date">
      </div>
      <div class="buttons">
        <button @click="createProfile" class="button" :class="{'fade-out': !profileOK}">Create profile</button>
        <button @click="checkProfileID(pID)" class="button">Check ID</button>
      </div>
      <div class="buttons">
        <button @click="signOut()" class="button">Sign out</button>
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  margin: 0;
  padding: 0;
  user-zoom: none;
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
}

input {
  font-size: 0.8125rem;
}

#form {
  margin: 0 auto;
  width: 22.5rem;
  padding: 1rem;
}

.qr {
  display: block !important;
  width: 5.625rem  !important;
  height: 5.625rem !important;
  user-select: none;
  margin: 0 auto;
}

.diceBtn {
  transform: translate(-2.35rem, 0.7rem);
  position:absolute;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0.3rem;
}

input[type=text] {
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
  border: 2px solid rgba(0, 0, 0, 0.45);
  outline: none;
  margin: 0.3125rem 0;
  padding: 0.625rem;
  border-radius: 6.5px;
  width: 20rem;
}

input[type=text]:focus {
  border: 2px solid rgba(0, 0, 0, 1);
}

input[type=date] {
  border: 2px solid rgba(0, 0, 0, 0.45);
  outline: none;
  margin: 0.3125rem 0;
  padding: 0.625rem;
  border-radius: 6.5px;
  width: 20rem;
}

input[type=date]:focus {
  border: 2px solid rgba(0, 0, 0, 1);
}

.outline-green {
  border: 2px solid rgb(137, 182, 22) !important;
}

.outline-red {
  border: 2px solid rgb(185, 34, 52) !important;
  box-shadow: 0px 0px 0.5rem rgba(255, 0, 30, 0.25);
}

button {
  font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  cursor: pointer;
}


.buttons {
  width: 21.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 1;
  justify-content: space-between;
  gap: 0.625rem;
}

.permission {
  width: 21.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-grow: 4;
  justify-content: space-between;
  gap: 0.625rem;
}

.ptag {
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.45);
  color: rgba(255,255,255,1);
  outline: none;
  margin: 0.3125rem 0;
  padding: 10px;
  border-radius: 6.5px;
  width: 7.5rem;
  font-size: 0.8125rem;
}

.button {
  font-weight: bold;
  text-decoration: none;
  background: rgba(0,0,0,1);
  padding: 1rem 0;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  color: rgba(255,255,255,1);
  margin: 0.3125rem auto ;
  float: none;
  user-select: none;
  /* width: 100%; */
  flex-grow: 1;
}

.fade-out {
  opacity: 0.45;
}

</style>

<script>
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore";
import { auth, db } from "../firebasedb";
import { signOut } from "firebase/auth";
import { store } from "../store"


export default {
  name: "Create",
  components: {
    
  },

  data() {
    return {
      // CSS variables
      profileOK: true,

      // Profile input variables
      pID: this.generateAlphanumeric(6),
      pName: '',
      pAffiliation: 'Fablab Nordvest',
      pRole: 'Member',
      pMobile: '',
      pEmail: '',
      p3D01: '',
      pCNC01: '',
      pLaser01: '',
      pRouter01: '',
      cUID: store.state.user.uID,
    }
  },

  methods: {
    signOut() {
        signOut(auth)
        .then(() => {
          console.log("Succesfully signed out!")
          this.$router.replace({
            name: "login"
          });
        });
    },

    resetInput() {
      this.pID = this.generateAlphanumeric(6)
      this.pName = ''
      this.pAffiliation = ''
      this.pRole = ''
      this.pMobile = ''
      this.pEmail = ''
      this.p3D01 = ''
      this.pCNC01 = ''
      this.pLaser01 = ''
      this.pRouter01 = ''
    },

    generateAlphanumeric(length) {
        // let alphaN = Math.random().toString(36).slice(2,8)
        var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789";
        let alphaN = [...Array(length)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
        console.log(alphaN)
        // this.checkProfileID(alphaN)
        return alphaN
    },

    checkDate(date) {
        if (date != "") {
            return Timestamp.fromDate(new Date(date))
        }
        else {
            return null
        }
    },

    checkProfileID(id) {
      if (id.length >= 6) {
        getDoc(doc(db, 'data', id))
        .then(snapshot => {
        if (snapshot.exists()) {
          console.log(`ERROR: Profile ID [${id}] already exists.`)
          this.profileOK = false
        } else {
          console.log(`Profile ID [${id}] is available!`)
          this.profileOK = true
      }  
      })
      }
    },

    createProfile() {
      const docData = {
          ID: this.pID,
          name: this.pName,
          affiliation: this.pAffiliation,
          role: this.pRole,
          mobile: this.pMobile,
          email: this.pEmail,
          created: Timestamp.now(),
          creator: this.cUID,

          permissions: {
            CNC01: this.checkDate(this.pCNC01),
            Laser01: this.checkDate(this.pLaser01),
            Prusa01: this.checkDate(this.p3D01),
            Router01: this.checkDate(this.pRouter01),
          }
      };

      if ( this.pName == "" || this.pAffiliation == "" || this.pRole == "") {
        alert("Error: One or more fields are empty.")
        return
      }
      if (this.pID.length < 6) {
        alert("Error: Profile ID must be at least 6 characters.")
        return
      }
      if (this.profileOK == false) {
        alert("Error: This profile ID already exists.")
        return
      }

      this.checkProfileID(this.pID)

      setDoc(doc(db, "data", this.pID), docData);
      console.log("Created profile!")
      this.resetInput()
      this.checkProfileID(this.pID)
      console.log(docData)
    }
  },
  created() {
    console.log(`Stored UID: ${this.cUID}`)
    // setDoc(doc(db, "data", "two"), docData);

    /*
    const docData = {
            pID: this.pID,
            pName: 'John Appleseed',
            booleanExample: true,
            numberExample: 3.14159265,
            dateExample: Timestamp.fromDate(new Date("December 10, 1815")),
            arrayExample: [5, true, "hello"],
            nullExample: null,
            objectExample: {
                a: 5,
                b: {
                    nested: "foo"
                }
            }
        };
    */

    /* 
    const data = {
        name: 'Los Angeles',
        state: 'CA',
        country: 'USA'
    };

    // Add a new document in collection "cities" with ID 'LA'
    const res = db.collection('cities').doc('LA').set(data);
    res()
    */
  },
}
</script>